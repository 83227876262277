import React from "react";
import { useNotify, Title } from "react-admin";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { fetchJSON } from "../dataProvider";
import { withUser } from "../common/util/hocs";
import { formatCNPJ } from "../common/util/formatter";

const useStyles = makeStyles((theme) => ({
  cover: {
    height: "100%",
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
  dark: {
    fontSize: "6em",
  },
  kitchen: {
    fontSize: "3.5em",
  },
}));

export function DarkKitchenLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <div className={classes.coverMargin}>
        <span className={classes.dark}>Dark</span>
        <br />
        <span className={classes.kitchen}>Kitchen</span>
      </div>
    </div>
  );
}

function DarkKitchen(props) {
  const notify = useNotify();

  const {
    loja,
    usuario: { lojas },
  } = props;
  const lojaId = loja.id;

  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(null);

  React.useEffect(() => {
    fetchJSON(`Loja_dark_kitchens`, null, { filter: { where: { lojaId } } })
      .then((list) => {
        setChecked(list.map((l) => l.lojaDarkKitchenId));
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao recuperar filiais", "warning");
      });
  }, [lojaId, notify]);

  const handleToggle = (lojaDarkKitchenId) => async () => {
    const currentIndex = checked.indexOf(lojaDarkKitchenId);
    const newChecked = [...checked];
    const addToArray = currentIndex === -1;

    try {
      setLoading(true);
      if (addToArray) {
        await createFilial(lojaDarkKitchenId);
        newChecked.push(lojaDarkKitchenId);
      } else {
        await deleteFiliais(lojaDarkKitchenId);
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    } catch (err) {
      setErro(err);
      notify("Erro ao configurar filial", "warning");
    } finally {
      setLoading(false);
    }
  };

  const createFilial = async (lojaDarkKitchenId) => {
    return fetchJSON(`Loja_dark_kitchens`, {
      method: "POST",
      body: {
        lojaId,
        lojaDarkKitchenId,
      },
    });
  };

  const deleteFiliais = async (lojaDarkKitchenId) => {
    const filiaisToDelete = await fetchJSON(`Loja_dark_kitchens`, null, {
      filter: { where: { lojaId, lojaDarkKitchenId } },
    });

    return Promise.all(
      filiaisToDelete.map((f) =>
        fetchJSON(`Loja_dark_kitchens/del`, {
          method: "DELETE",
          body: {
            id: f.id,
            lojaId,
          },
        })
      )
    );
  };

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Configuração Dark Kitchen" />
      <Box sx={{ width: 1 / 4 }}>
        <DarkKitchenLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 10 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            {`Dark Kitchen: ${loja.nome_fantasia}`}
            <br />
            {formatCNPJ(loja.cnpj)}
            <br />
            {`${loja.logradouro}, ${loja.numero}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Selecione quais lojas vão enviar os pedidos para a Dark Kitchen:
          </Typography>
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}

          {Array.isArray(checked) && (
            <List dense sx={{ width: "100%", maxWidth: 600 }}>
              {lojas
                .filter((l) => l.id !== loja.id)
                .filter((l) => !!l.appDeliveryUri)
                .map((l) => (
                  <ListItem
                    key={l.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(l.id)}
                        checked={checked.indexOf(l.id) !== -1}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton disabled={loading}>
                      <ListItemAvatar>
                        <Avatar src={`${l.fotoUrl}`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${l.nome_fantasia} (${l.appDeliveryUri})`}
                        secondary={
                          <>
                            <span>{`${formatCNPJ(l.cnpj)}`}</span>
                            <br />
                            <span>{`${l.logradouro}, ${l.numero}`}</span>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}

export default withUser(DarkKitchen);
